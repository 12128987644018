import React from "react";
import "../App.css";

function Loading() {
  return (
    <div className="loading-container">
      <img
        src="https://www.biddingwave.it/wp-content/uploads/2024/02/bidding-wave-crop.png"
        alt="Bidding Wave Logo"
        className="loading-logo"
      />
    </div>
  );
}

export default Loading;
