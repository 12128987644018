import React, { useState, useEffect } from "react";
import axios from "axios";
import "../App.css";
import Loading from "./Loading";
import { motion } from "framer-motion";

function Slider() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slides, setSlides] = useState([]);
  const [loading, setLoading] = useState(true);
  const [remainingTime, setRemainingTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [previewImages, setPreviewImages] = useState([]);

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const apiToken =
          "1917|wNxlbKknxvySAWxs5VpnNzN3OuQQm3Fo06UJMuUrc972c9b3";
        const response = await axios.get(
          "https://gestionale.biddingwave.it/api/auth/getRecords/immobile?filterPayload=%7B%22immobile%22:%7B%22status%22:%5B%22Attiva%22,%22Conclusa%22,%20%22Aggiudicata%22%5D%7D%7D",
          {
            headers: {
              Authorization: `Bearer ${apiToken}`,
            },
          }
        );

        setSlides(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch images", error);
        setLoading(false);
      }
    };

    fetchSlides();
  }, []);

  useEffect(() => {
    const fetchPreviewImages = async () => {
      try {
        const slide = slides[currentSlide];
        if (!slide) {
          return;
        }
        const response = await axios.get(
          `https://gestionale.biddingwave.it/api/getRecordView/immobile/${slide.id}`
        );
        const iamges = response.data.iamges || [];
        const imagesArray = [];
        iamges.forEach((image, index) => {
          imagesArray.push(image);
        });
        setPreviewImages(imagesArray);
      } catch (error) {
        console.error("Failed to fetch preview images", error);
      }
    };

    fetchPreviewImages();
  }, [currentSlide, slides]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((currentSlide) =>
        currentSlide === slides.length - 1 ? 0 : currentSlide + 1
      );
    }, 20000);

    return () => clearInterval(interval);
  }, [slides.length]);

  useEffect(() => {
    const updateTimer = () => {
      if (slides.length > 0) {
        const deadline = new Date(slides[currentSlide].deadline).getTime();
        const currentTime = new Date().getTime();
        const distance = deadline - currentTime;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setRemainingTime({ days, hours, minutes, seconds });
      }
    };

    updateTimer();

    const interval = setInterval(updateTimer, 1000);

    return () => clearInterval(interval);
  }, [currentSlide, slides]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="slider-container">
      <div className="main-slide">
        {slides[currentSlide].houseType && (
          <motion.span
            key={currentSlide}
            className="houseType"
            initial="hidden"
            animate="visible"
            variants={{
              hidden: { opacity: 0, y: 20 },
              visible: { opacity: 1, y: 0 },
            }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
          >
            {slides[currentSlide].houseType}
          </motion.span>
        )}
        {slides[currentSlide].codiceImmobile && (
          <motion.span
            key={currentSlide}
            className="codiceImmobile"
            initial="hidden"
            animate="visible"
            variants={{
              hidden: { opacity: 0, y: 20 },
              visible: { opacity: 1, y: 0 },
            }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
          >
            {slides[currentSlide].codiceImmobile}
          </motion.span>
        )}
        {slides[currentSlide].actualOffer && (
          <motion.span
            key={currentSlide}
            className="actualOffer"
            initial="hidden"
            animate="visible"
            variants={{
              hidden: { opacity: 0, y: 20 },
              visible: { opacity: 1, y: 0 },
            }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
          >
            {parseFloat(slides[currentSlide].actualOffer).toLocaleString(
              "it-IT",
              {
                style: "currency",
                currency: "EUR",
              }
            )}
          </motion.span>
        )}
        <motion.img
          key={slides[currentSlide].id}
          src={slides[currentSlide].firstImage}
          alt="Main Slide"
          className="main-slide-image"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        />
      </div>
      <div className="property-info">
        <div className="timing">
          {remainingTime.days === 0 &&
          remainingTime.hours === 0 &&
          remainingTime.minutes === 0 &&
          remainingTime.seconds === 0 ? (
            <p>Terminata il: {slides[currentSlide].deadline}</p>
          ) : (
            <span>
              {remainingTime.days} Giorni, {remainingTime.hours}h,{" "}
              {remainingTime.minutes}m, {remainingTime.seconds}s
            </span>
          )}
        </div>
        <h2 className="rilanci">
          {slides[currentSlide].customRelations.MasterDetail.Rilanci
            .relatedRecords.length > 0
            ? slides[currentSlide].customRelations.MasterDetail.Rilanci
                .relatedRecords[0].label
            : ""}
        </h2>
      </div>
      <div className="preview-slide">
        {previewImages && previewImages.length > 0 ? (
          <div className="grid-container">
            {previewImages.map((image, index) => (
              <motion.img
                key={index}
                src={image}
                alt={`Preview Slide ${index}`}
                className={`preview-slide-image ${
                  index === currentSlide ? "active" : ""
                }`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: index * 0.4 }}
              />
            ))}
          </div>
        ) : (
          <div>Nessuna immagine di anteprima disponibile</div>
        )}
      </div>
    </div>
  );
}

export default Slider;
