// src/App.js
import React from "react";
import Slider from "./components/Slider"; // Importa il componente Slider
import "./App.css";

function App() {
  return (
    <div className="App">
      <Slider /> {/* Utilizza il componente Slider */}
    </div>
  );
}

export default App;
